//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton, PageSpinner } from "@arangodb/ui";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { CACertificateForm } from "./CACertificateForm";
import { CACertificateFormValues } from "./CreateCACertificateModal";

interface IUpdateCACertificateModalProps {
  caCertificateId: string;
  onCACertificateUpdated: (caCerticifateId: string) => void;
  onClose: () => void;
}

const useUpdateCACertificate = () => useMutation({ mutationFn: apiClients.cryptoClient.UpdateCACertificate });

const UpdateCACertificateModal = ({ caCertificateId, onCACertificateUpdated, onClose }: IUpdateCACertificateModalProps) => {
  const toast = useToast();
  const { project, cacertificates } = useProjectDetailsContext();
  const currentCACertificate = cacertificates?.items?.find((certificate) => certificate.id === caCertificateId);

  const {
    name: currentName = "",
    description: currentDescription = "",
    use_well_known_certificate: currentUseWellKnownCertificate,
    locked: isCurrentLocked,
  } = currentCACertificate || {};

  const { mutateAsync: updateCACertificate, status: updateCACertificateStatus } = useUpdateCACertificate();
  const onUpdateCACertificate = async (values: CACertificateFormValues) => {
    const { name, description, useWellKnownCertificate, isLocked } = values;

    const newCACertificate = await updateCACertificate({
      id: caCertificateId,
      project_id: project.id,
      name,
      description,
      use_well_known_certificate: useWellKnownCertificate === "well-known",
      locked: isLocked,
    });

    onCACertificateUpdated(newCACertificate.id || "");

    toast({
      title: `Certificate ${name} updated`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (updateCACertificateStatus === "error") {
      toast({
        title: `Failed to update certificate`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [updateCACertificateStatus]);

  const isUpdatingCACertificate = updateCACertificateStatus === "loading";

  return (
    <Formik<CACertificateFormValues>
      enableReinitialize
      initialValues={{
        name: currentName || "",
        description: currentDescription || "",
        useWellKnownCertificate: currentUseWellKnownCertificate ? "well-known" : "self-signed",
        isLocked: isCurrentLocked || false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        description: Yup.string(),
        useWellKnownCertificate: Yup.string().required("Required"),
        isLocked: Yup.boolean().required("Required"),
      })}
      onSubmit={onUpdateCACertificate}
    >
      <Modal isOpen={true} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent as={Form}>
          <ModalHeader>Edit certificate {currentName}</ModalHeader>
          <PageSpinner isLoading={isUpdatingCACertificate} />
          <ModalBody>
            <CACertificateForm />
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onClose} disabled={isUpdatingCACertificate}>
              Cancel
            </Button>
            <FormButton colorScheme="green" isLoading={isUpdatingCACertificate} isDisabled={isUpdatingCACertificate}>
              Update certificate
            </FormButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Formik>
  );
};

export default UpdateCACertificateModal;
