//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InputControl } from "@arangodb/ui";
import { useField, useFormikContext } from "formik";
import React from "react";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchLimits } from "./useFetchLimits";
import { useReinitializeNumberOfNodes } from "./useReinitializeNumberOfNodes";

export const NumberOfNodesInput = ({ isDisabled }: { isDisabled?: boolean }) => {
  useReinitializeNumberOfNodes();
  const [modelIdField] = useField("modelId");
  const values = useFormikContext<NewDeploymentFormValues>().values;
  const { data: limits } = useFetchLimits({ regionId: values.regionId });
  const { node_count } = limits || {};
  const { min = 1, max = 1 } = node_count || {};
  // we ignore the valuea here as we just want to validate
  const [,] = useField({
    name: "numberOfNodes",
    validate: (value: number) => {
      // for developer & free model, number of nodes is always 1
      if (modelIdField.value === "developer" || modelIdField.value === "free") {
        if (value !== 1) {
          return `Number of nodes must be 1`;
        }
        return;
      }
      if (value < min || value > max) {
        return `Number of nodes must be between ${min} and ${max}`;
      }
    },
  });

  return (
    <InputControl
      inputProps={{
        type: "number",
        min: min,
        max: max,
        placeholder: "Node",
      }}
      isRequired
      isDisabled={modelIdField.value !== "sharded" || isDisabled}
      label="Number of nodes"
      name="numberOfNodes"
      tooltip="The number of nodes in the deployment. This can only be set for sharded deployments. For other types, the number of nodes is fixed."
    />
  );
};
