//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Button, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Project } from "../../api/lib";
import { SingleSelect } from "@arangodb/ui";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

const getInitialSelectedProjectId = (projects: Array<Project> | undefined) => {
  if (!projects || projects.length === 0) {
    return "";
  }
  return (
    projects.sort((projectA, projectB) => {
      if (!projectA.created_at || !projectB.created_at) {
        return 0;
      }
      return projectA.created_at > projectB.created_at ? -1 : 1;
    })[0].id || ""
  );
};

export const NewDeploymentProjectSelectorModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const history = useHistory();
  const { projects } = useDashboardContext();
  const memoizedProjectOptions = React.useMemo(
    () =>
      projects?.items?.map((project) => ({
        label: project.name || "",
        value: project.id || "",
      })) || [],
    [projects]
  );
  const initialSelectedProjectId = getInitialSelectedProjectId(projects?.items);
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>(initialSelectedProjectId);
  const selectedProject = projects?.items?.find((project) => project.id === selectedProjectId);
  const initialSelectedProject = projects?.items?.find((project) => project.id === initialSelectedProjectId);
  const defaultValue = {
    label: initialSelectedProject?.name || "",
    value: initialSelectedProjectId || "",
  };
  const { checkSpecificProjectPermission } = useCheckPermissionByUrl();
  const hasCreateDeploymentPermission = checkSpecificProjectPermission("data.deployment.create", selectedProject || initialSelectedProject || {});
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!selectedProjectId || !hasCreateDeploymentPermission) {
              return;
            }
            history.push(Routes.dashboard_project_deployments_createWithId(selectedProjectId));
          }}
        >
          <ModalHeader>Select a project to create a deployment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Project</FormLabel>
              <SingleSelect
                autoFocus
                defaultValue={defaultValue}
                options={memoizedProjectOptions}
                onChange={(selectedProject) => {
                  setSelectedProjectId(selectedProject?.value || "");
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter gap="3">
            <Button onClick={onClose}>Close</Button>
            <Button type="submit" colorScheme="green" isDisabled={!hasCreateDeploymentPermission}>
              Continue
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
