//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner } from "@arangodb/ui";
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import apiClients from "../../../api/apiclients";
import { ModelSpec, Notebook } from "../../../api/notebook/v1/notebook";
import { ListAction } from "../../../ui/lib";
import { EditNotebookForm } from "./EditNotebookForm";
import { useFetchNotebookModels } from "./useFetchNotebookModels";
import { useFetchNotebooksList } from "./useFetchNotebooksList";

export interface NotebookFormValues {
  name: Notebook["name"];
  description: Notebook["description"];
  modelId: ModelSpec["notebook_model_id"];
  diskSize: ModelSpec["disk_size"];
}

const EditNotebookView = ({ notebookId }: { notebookId: Notebook["id"] }) => {
  const toast = useToast();
  const { onOpen: onOpenModal, onClose, isOpen } = useDisclosure();
  const { data: notebookModels = {} } = useFetchNotebookModels();
  const { refetch: refetchNotebooksList } = useFetchNotebooksList();

  const [oldNotebook, setOldNotebook] = useState<Notebook>({});
  const [isLoadingNotebook, setIsLoadingNotebook] = useState(false);
  const [isSavingNotebook, setIsSavingNotebook] = useState(false);
  const [errorFetchingNotebook, setErrorFetchingNotebook] = useState(undefined);

  const notebookModelList = notebookModels?.items || [];

  const updateNotebook = async (values: NotebookFormValues) => {
    setIsSavingNotebook(true);

    const { name, description, modelId, diskSize } = values;

    try {
      await apiClients.notebookClient.UpdateNotebook({
        ...oldNotebook,
        id: notebookId || "",
        deployment_id: oldNotebook.deployment_id || "",
        name,
        description,
        model: {
          notebook_model_id: modelId,
          disk_size: diskSize,
        },
      });
      refetchNotebooksList();
      toast({
        title: `Notebook updated`,
        description: `Notebook "${name}" has been updated`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: `Failed to update notebook ${oldNotebook.name}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      getNotebook(notebookId || "");
      setIsSavingNotebook(false);
      onClose();
    }
  };

  const getNotebook = async (notebookId: string) => {
    setIsLoadingNotebook(true);
    setErrorFetchingNotebook(undefined);
    try {
      const response = await apiClients.notebookClient.GetNotebook({ id: notebookId });
      setOldNotebook(response);
    } catch (err) {
      setErrorFetchingNotebook(err);
      toast({
        title: `Failed to fetch notebook with id ${notebookId}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingNotebook(false);
    }
  };

  const onOpen = () => {
    getNotebook(notebookId || "");
    onOpenModal();
  };

  return (
    <>
      <ListAction icon="edit" tooltip="Edit notebook" onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          {(isLoadingNotebook || isSavingNotebook) && <PageSpinner isLoading />}
          <ModalHeader>Edit Notebook {oldNotebook.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorFetchingNotebook ? (
              <Box height="100px" alignContent="center">
                <Text>There was an error while fetching the notebook with id {notebookId}. Please try again later.</Text>
              </Box>
            ) : (
              <Formik<NotebookFormValues>
                enableReinitialize
                initialValues={{
                  name: oldNotebook.name,
                  description: oldNotebook.description,
                  modelId: oldNotebook.model?.notebook_model_id || notebookModelList[0]?.id || "",
                  diskSize: oldNotebook.model?.disk_size || notebookModelList[0]?.initial_disk_size || 1,
                }}
                validationSchema={Yup.object({
                  name: Yup.string().required("Required"),
                  description: Yup.string(),
                  modelId: Yup.string().required("Required"),
                  diskSize: Yup.number().required("Required"),
                })}
                onSubmit={updateNotebook}
              >
                <EditNotebookForm oldNotebook={oldNotebook} onClose={onClose} />
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditNotebookView;
