//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InputControl, RadioGroupControl, SwitchControl, TextareaControl } from "@arangodb/ui";
import { Grid, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";

const CACertificateForm = () => {
  return (
    <Grid gap="4" templateColumns="1fr">
      <InputControl
        isRequired
        label="Name"
        name="name"
        inputProps={{
          placeholder: `Name (e.g. "MyCompanyCert-${moment().year()}")`,
        }}
      />

      <TextareaControl name="description" label="Description" inputProps={{ placeholder: "Short description" }} />

      <RadioGroupControl
        name="useWellKnownCertificate"
        label="Type of certificate"
        htmlFor="useWellKnownCertificate"
        id="useWellKnownCertificate"
        radioOptions={[
          { value: "self-signed", label: "Self-signed only" },
          { value: "well-known", label: "Let's encrypt + Self-signed" },
        ]}
      />
      <Text fontSize="xs">
        Let's Encrypt certificate is used for port 8529, while self-signed one for port 18529. Let's Encrypt TLS certificate is accepted automatically by all
        modern browsers.
      </Text>

      <SwitchControl label="Locked" name="isLocked" />
      <Text fontSize="xs">Locked certificates cannot be deleted.</Text>
    </Grid>
  );
};

export { CACertificateForm };
