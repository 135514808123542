//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton } from "@arangodb/ui";
import { Flex, Grid, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { LinkText } from "../../components/LinkText";
import { SummaryBox } from "./SummaryBox";
import { TermsAndConditionsModal } from "./TermsAndConditionsModal";

export const RightSection = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Grid templateRows="repeat(auto-fill, 1fr)" gap="4" height="100%" overflow="auto">
      <SummaryBox />
      <Flex
        paddingTop="2"
        paddingRight="2"
        paddingLeft="4"
        paddingBottom="4"
        gap="2"
        direction="column"
        backgroundColor="white"
        position="sticky"
        bottom="0"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <Text fontSize="sm" color="gray.500">
          By creating a deployment, you accept the <LinkText onClick={onOpen}>terms and conditions</LinkText>
          .
          <TermsAndConditionsModal isOpen={isOpen} onClose={onClose} />
        </Text>
        <FormButton marginTop={4} colorScheme="green">
          Create deployment
        </FormButton>
      </Flex>
    </Grid>
  );
};
