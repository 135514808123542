//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { MultiSelectControl } from "@arangodb/ui";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

const useFetchRoles = () => {
  const { selectedOrganization } = useDashboardContext();

  return useQuery(["roles"], async () => {
    const roles = await apiClients.iamClient.ListRoles({
      context_id: selectedOrganization.id,
    });
    return roles;
  });
};
export const RolesDropdown = () => {
  const { data: roles, isLoading } = useFetchRoles();
  const options = roles?.items?.map((role) => ({
    label: role.name || "",
    value: role.id || "",
  }));
  return (
    <MultiSelectControl
      label="Roles"
      name="roles"
      selectProps={{
        options,
        isLoading,
        placeholder: "Select roles",
        closeMenuOnSelect: false,
      }}
    />
  );
};
