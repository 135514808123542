//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton, PageSpinner } from "@arangodb/ui";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import apiClients from "../../api/apiclients";
import { CACertificate } from "../../api/lib";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { CACertificateForm } from "./CACertificateForm";

export interface CACertificateFormValues {
  name: CACertificate["name"];
  description: CACertificate["description"];
  useWellKnownCertificate: "self-signed" | "well-known";
  isLocked: CACertificate["locked"];
}

interface ICreateCACertificateModalProps {
  onNewCACertificateCreated: (caCertificate: CACertificate) => void;
  onClose: () => void;
}

const useCreateCACertificate = () => useMutation({ mutationFn: apiClients.cryptoClient.CreateCACertificate });

const CreateCACertificateModal = ({ onClose, onNewCACertificateCreated }: ICreateCACertificateModalProps) => {
  const toast = useToast();
  const { project } = useProjectDetailsContext();

  const { mutateAsync: createCACertificate, status: createCACertificatetatus } = useCreateCACertificate();

  const onCreateCACertificate = async (values: CACertificateFormValues) => {
    const { name, description, useWellKnownCertificate, isLocked } = values;
    const newCACertificate = await createCACertificate({
      project_id: project.id,
      name,
      description,
      use_well_known_certificate: useWellKnownCertificate === "well-known",
      locked: isLocked,
    });
    onNewCACertificateCreated(newCACertificate);
    toast({
      title: `Certificate ${name} created`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (createCACertificatetatus === "error") {
      toast({
        title: `Failed to create certificate`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [createCACertificatetatus]);

  const isCreatingCACertificate = createCACertificatetatus === "loading";

  return (
    <Formik<CACertificateFormValues>
      enableReinitialize
      initialValues={{
        name: "",
        description: "",
        useWellKnownCertificate: "well-known",
        isLocked: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        description: Yup.string(),
        useWellKnownCertificate: Yup.string().required("Required"),
        isLocked: Yup.boolean().required("Required"),
      })}
      onSubmit={onCreateCACertificate}
    >
      <Modal isOpen={true} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent as={Form}>
          <ModalHeader>New certificate</ModalHeader>
          <PageSpinner isLoading={isCreatingCACertificate} />
          <ModalBody>
            <CACertificateForm />
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onClose} disabled={isCreatingCACertificate}>
              Cancel
            </Button>
            <FormButton colorScheme="green" isLoading={isCreatingCACertificate} isDisabled={isCreatingCACertificate}>
              Create certificate
            </FormButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Formik>
  );
};

export default CreateCACertificateModal;
