//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner } from "@arangodb/ui";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import apiClients from "../../../api/apiclients";
import { ModelSpec, Notebook } from "../../../api/notebook/v1/notebook";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { CreateNotebookForm } from "./CreateNotebookForm";
import { useFetchNotebookModels } from "./useFetchNotebookModels";

export interface NotebookFormValues {
  name: Notebook["name"];
  description: Notebook["description"];
  modelId: ModelSpec["notebook_model_id"];
  diskSize: ModelSpec["disk_size"];
}

const CreateNotebookView = ({ onClose, onSuccess, isOpen }: { isOpen: boolean; onClose: () => void; onSuccess: () => Promise<unknown> }) => {
  const toast = useToast();

  const deploymentId = useDeploymentStore((state) => state.deployment.id);

  const { isLoading: isLoadingNotebookModels, data: notebookModels = {}, isError: isFetchError } = useFetchNotebookModels();
  const notebookModelList = notebookModels?.items || [];

  const onSubmit = async (values: NotebookFormValues) => {
    const { name, description, modelId, diskSize } = values;

    try {
      const request = {
        name,
        description,
        deployment_id: deploymentId,
        model: {
          notebook_model_id: modelId,
          disk_size: diskSize,
        },
      };
      await apiClients.notebookClient.CreateNotebook(request);
      await onSuccess();
      toast({
        title: `Notebook created`,
        description: `Notebook "${name}" has been created`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (err) {
      toast({
        title: `Failed to create notebook ${name}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isFetchError) {
      toast({
        title: "Failed to fetch notebook models",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isFetchError]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        {isLoadingNotebookModels && <PageSpinner isLoading />}
        <ModalHeader>Create New Notebook</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik<NotebookFormValues>
            enableReinitialize
            initialValues={{
              name: "",
              description: "",
              modelId: notebookModelList[0]?.id || "",
              diskSize: notebookModelList[0]?.initial_disk_size || 1,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              description: Yup.string(),
              modelId: Yup.string().required("Required"),
              diskSize: Yup.number().required("Required"),
            })}
            onSubmit={onSubmit}
          >
            <CreateNotebookForm notebookModels={notebookModelList} onClose={onClose} isLoadingNotebookModels={isLoadingNotebookModels} />
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateNotebookView;
