//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { SingleSelectControl } from "@arangodb/ui";
import { Button, Flex, FormHelperText, Text, useDisclosure } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { SupportPlanModal } from "./SupportPlanModal";
import { useFetchSupportPlans } from "./useFetchSupportPlans";

export const SupportPlanSelector = () => {
  const [modelField] = useField("modelId");
  const { data: supportPlans } = useFetchSupportPlans({
    modelId: modelField.value,
  });
  const { isExisting } = useFetchDeploymentForEdit();
  const { onOpen: onOpenSupportPlanModal, onClose: onCloseSupportPlanModal, isOpen: isSupportPlanModalOpen } = useDisclosure();
  if (isExisting) {
    return null;
  }

  return (
    <>
      <SingleSelectControl
        label="Support Plan"
        name="supportPlanId"
        isRequired
        selectProps={{
          options: supportPlans?.items?.map((supportPlan) => ({
            label: supportPlan.name || "",
            value: supportPlan.id || "",
          })),
        }}
        tooltip={
          <Flex direction="column" gap="2">
            <Text>Every deployment is covered with its own Support plan.</Text>
            <Text>This support plan defines the SLA for support related to this deployment.</Text>
            <Text>Note that (on request) you can change to a higher support plan, but you cannot move back to a smaller support plan.</Text>
          </Flex>
        }
      >
        <FormHelperText fontSize="sm" color="gray.500">
          <Button
            variant="link"
            color="blue.500"
            size="sm"
            _hover={{
              color: "blue.600",
            }}
            onClick={(e) => {
              onOpenSupportPlanModal();
            }}
          >
            Click here
          </Button>{" "}
          to know more about the support plans
        </FormHelperText>
      </SingleSelectControl>
      <SupportPlanModal isOpen={isSupportPlanModalOpen} onClose={onCloseSupportPlanModal} />
    </>
  );
};
