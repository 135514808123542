//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InputControl, SingleSelectControl } from "@arangodb/ui";
import { Box, Button } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useDashboardContext } from "../DashboardContextProvider";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchDBVersions } from "./useFetchDBVersions";

export const DBVersionSelector = () => {
  const { selectedOrganization } = useDashboardContext();
  const isAllowedToUseCustomImages = !!selectedOrganization.is_allowed_to_use_custom_images;
  const { initialValues } = useFormikContext<NewDeploymentFormValues>();
  const [, , customImageFieldHelpers] = useField({
    name: "customImage",
    validate: (value: string) => {
      if (isCustomImageEnabled && !value) {
        return "Either a custom image or a DB version is required";
      }
    },
  });
  const hasInitialCustomImage = !!initialValues.customImage;
  const [isCustomImageEnabled, setIsCustomImageEnabled] = React.useState(hasInitialCustomImage);

  return (
    <Box position="relative" flexShrink={0}>
      {isAllowedToUseCustomImages && (
        <Button
          variant="ghost"
          size="xs"
          isDisabled={hasInitialCustomImage}
          onClick={() => {
            if (!isCustomImageEnabled) {
              setIsCustomImageEnabled(true);
            } else {
              // when swithcing back to default, reset custom image value to avoid issues
              setIsCustomImageEnabled(false);
              customImageFieldHelpers.setValue("");
            }
          }}
          position="absolute"
          right="0"
          top="0"
          zIndex={1}
        >
          {isCustomImageEnabled ? "Use default image" : "Use custom image"}
        </Button>
      )}
      {isCustomImageEnabled ? (
        <InputControl
          tooltip="Enter the custom image to use for your deployment."
          label="Custom Image"
          name="customImage"
          inputProps={{
            placeholder: "Custom image",
          }}
          isRequired
        />
      ) : (
        <VersionDropdown />
      )}
    </Box>
  );
};

const VersionDropdown = () => {
  const { data: versions } = useFetchDBVersions();

  return (
    <SingleSelectControl
      label="DB Version"
      isRequired
      name="dbVersion"
      selectProps={{
        options: versions?.items?.map((version) => ({
          label: version.version || "",
          value: version.version || "",
        })),
      }}
      tooltip="Select the ArangoDB version that your deployment will run."
    />
  );
};
