//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner } from "@arangodb/ui";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Icon as SemanticIcon } from "semantic-ui-react";
import { LinkText } from "../../components/LinkText";
import { Routes } from "../../routes";
import { formatUnroundedPrice } from "../../util/Price";
import { useDashboardContext } from "../DashboardContextProvider";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { usePriceSummary } from "./usePriceSummary";

export const PriceDetailsModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { selectedOrganization, isFreeTier } = useDashboardContext();
  const { isFetching } = usePriceSummary();
  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pricing explained</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isFetching && <PageSpinner isLoading />}
          <Flex direction="column" gap="6">
            {isFreeTier && <FreeTierMessage />}
            <DeploymentPriceSection />
            <NetworkPriceSection />
            <BackupPriceSection />
            {selectedOrganization?.tier?.has_auditlog_feature && <AuditLogPriceSection />}
            <BillingCycleSection />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PriceDetailsModal;

const SectionHeading = ({ icon, children }: { icon: string; children: React.ReactNode }) => {
  return (
    <Flex alignItems="baseline" gap="1">
      <Icon as={SemanticIcon} name={icon} className="secondary-text" />
      <Heading size="sm">{children}</Heading>
    </Flex>
  );
};

const FreeTierMessage = () => {
  const { isPerpetualFreeTrialAvailable, selectedOrganization } = useDashboardContext();
  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="gift">Free trial</SectionHeading>
      <Text size="sm">
        You're currently in a free trial, there is no need to enter a payment method and you will not be billed for any usage. You can create one free-to-try
        deployment with a {isPerpetualFreeTrialAvailable ? 30 : 14} days trial period.
      </Text>
      <Text>
        To unlock all ArangoGraph functionality,{" "}
        <LinkText
          fontSize="sm"
          color="blue.600"
          textDecoration={"underline"}
          _hover={{
            color: "blue.700 !important",
            textDecoration: "underline",
          }}
          as={NavLink}
          to={Routes.dashboard_organization_billingWithId(selectedOrganization.id || "")}
        >
          enter billing information
        </LinkText>{" "}
        for your organization and add a payment method. The pricing information below shows what you'll pay after adding a payment method.
      </Text>
    </Flex>
  );
};

const DeploymentPriceSection = () => {
  const { deploymentPriceData, hasCredits } = usePriceSummary();
  const { isPrepaid } = useFetchDeploymentForEdit();
  if (isPrepaid) {
    return (
      <Flex direction="column" gap="2">
        <SectionHeading icon="database">Deployment Price</SectionHeading>
        <Text>The deployment is prepaid as part of your contract.</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="database">Deployment Price</SectionHeading>
      <Text>The deployment is priced per hour.</Text>
      <Table size="sm" border="1px solid" borderColor="gray.100">
        <Tbody>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per hour
            </Td>
            <Td>
              {hasCredits ? deploymentPriceData?.priceWithCredits?.price_per_hour : deploymentPriceData?.price?.price_per_hour}
              {hasCredits ? " credits" : deploymentPriceData?.currency.sign}
            </Td>
          </Tr>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per month
            </Td>
            <Td>
              {hasCredits ? 730 * (deploymentPriceData?.priceWithCredits?.price_per_hour || 0) : 730 * (deploymentPriceData?.price?.price_per_hour || 0)}
              {hasCredits ? " credits" : deploymentPriceData?.currency.sign}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  );
};

const NetworkPriceSection = () => {
  const { deploymentPriceData } = usePriceSummary();
  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="at">Network pricing</SectionHeading>
      <Text>Network transfer in/out of the deployment is priced per GB of data.</Text>
      <Table size="sm" border="1px solid" borderColor="gray.100">
        <Tbody>
          {deploymentPriceData?.price?.network_transfer_prices?.map((price) => {
            console.log(price);
            return (
              <React.Fragment key={price.description}>
                <Tr>
                  <Td width="40%" borderRight="1px solid var(--gray-100)">
                    {price.description} ingress
                  </Td>
                  <Td>
                    {price.ingress_price_per_gb
                      ? `${formatUnroundedPrice(price.ingress_price_per_gb, "", "")} ${deploymentPriceData?.currency.sign}/GB`
                      : "Free"}
                  </Td>
                </Tr>
                <Tr>
                  <Td width="40%" borderRight="1px solid var(--gray-100)">
                    {price.description} egress
                  </Td>
                  <Td>
                    {formatUnroundedPrice(price.egress_price_per_gb || 0, "", "")} {deploymentPriceData?.currency.sign}/GB
                  </Td>
                </Tr>
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

const BackupPriceSection = () => {
  const { deploymentPriceData } = usePriceSummary();
  const { backup_price } = deploymentPriceData?.price || { backup_price: { price_per_gb_per_hour: 0 } };
  const hourlyPrice = formatUnroundedPrice(backup_price?.price_per_gb_per_hour || 0, "", "");
  const monthlyPrice = formatUnroundedPrice(730 * (backup_price?.price_per_gb_per_hour || 0), "", "");
  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="disk">Backup pricing</SectionHeading>
      <Text>Uploaded backups are priced per GB of storage per hour.</Text>
      <Table size="sm" border="1px solid" borderColor="gray.100">
        <Tbody>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per hour
            </Td>
            <Td>
              {hourlyPrice} {deploymentPriceData?.currency.sign}/GB
            </Td>
          </Tr>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per month
            </Td>
            <Td>
              {monthlyPrice} {deploymentPriceData?.currency.sign}/GB
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  );
};

const AuditLogPriceSection = () => {
  const { deploymentPriceData } = usePriceSummary();
  const { auditlog_price } = deploymentPriceData?.price || { auditlog_price: { price_per_gb_per_hour: 0 } };
  const hourlyPrice = formatUnroundedPrice(auditlog_price?.price_per_gb_per_hour || 0, "", "");
  const monthlyPrice = formatUnroundedPrice(730 * (auditlog_price?.price_per_gb_per_hour || 0), "", "");
  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="history">Audit log pricing</SectionHeading>
      <Text>AuditLog (destination: cloud) are priced per GB of storage per hour.</Text>
      <Table size="sm" border="1px solid" borderColor="gray.100">
        <Tbody>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per hour
            </Td>
            <Td>
              {hourlyPrice} {deploymentPriceData?.currency.sign}/GB
            </Td>
          </Tr>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price per month
            </Td>
            <Td>
              {monthlyPrice} {deploymentPriceData?.currency.sign}/GB
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Text>AuditLog (destination: https-post) are priced per endpoint invocation and transmitted eventlogs per GB of data.</Text>
      <Table size="sm" border="1px solid" borderColor="gray.100">
        <Tbody>
          <Tr>
            <Td width="40%" borderRight="1px solid var(--gray-100)">
              Price for invocations
            </Td>
            <Td>
              {formatUnroundedPrice(auditlog_price?.https_post_invocation_price_per_1000 || 0, "", "")} {deploymentPriceData?.currency.sign}/1.000
            </Td>
          </Tr>
          <Td width="40%" borderRight="1px solid var(--gray-100)">
            Price for transmitted eventlogs
          </Td>
          <Td>
            {formatUnroundedPrice(auditlog_price?.https_post_body_size_price_per_gb || 0, "", "")} {deploymentPriceData?.currency.sign}/GB
          </Td>
        </Tbody>
      </Table>
    </Flex>
  );
};

const BillingCycleSection = () => {
  const { isPrepaid } = useFetchDeploymentForEdit();
  if (isPrepaid) {
    return (
      <Flex direction="column" gap="2">
        <SectionHeading icon="calendar">Billing cycle</SectionHeading>
        <Text>This is specified in your contract.</Text>
      </Flex>
    );
  }
  return (
    <Flex direction="column" gap="2">
      <SectionHeading icon="calendar">Billing cycle</SectionHeading>
      <Text>
        Once you enter your payment information, you will be charged at least once a month. If your usage exceeds a certain threshold, you will be charged
        earlier (up to once a week).
      </Text>
    </Flex>
  );
};
