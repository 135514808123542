//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { SingleSelectControl, OptionType } from "@arangodb/ui";
import { Flex, Text } from "@chakra-ui/react";
import { useFeature } from "flagged";
import React, { useMemo } from "react";
import { Props as ReactSelectProps } from "react-select";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { useFetchDeploymentModels } from "./useFetchDeploymentModels";

export const ModelSelector = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { data: models, isFetching } = useFetchDeploymentModels();
  const { data: deployment } = useFetchDeploymentForEdit();
  const isDeveloperModelEnabled = useFeature("model.developer");
  const modelOptions = useMemo(() => {
    return (
      models?.items
        ?.map((model) => {
          const isUpgradeAllowed = getIsUpgradeAllowed({ currentModel: deployment?.model?.model || "", newModel: model.id || "" });
          if (model.id === "developer" && !isDeveloperModelEnabled) {
            return false;
          }
          return {
            label: model.name || model.id || "",
            value: model.id || "",
            isDisabled: !isUpgradeAllowed,
          };
        })
        // TODO: consider ts-reset to avoid such hacks
        .filter(Boolean) as ReactSelectProps<OptionType, false>["options"]
    );
  }, [models, deployment, isDeveloperModelEnabled]);

  return (
    <SingleSelectControl
      label="Model"
      isRequired
      name="modelId"
      isDisabled={isDisabled}
      selectProps={{
        options: modelOptions,
        isLoading: isFetching,
        isDisabled: isFetching || isDisabled,
      }}
      tooltip={
        <Flex direction="column" gap="2">
          <Text>Define the configuration of your deployment.</Text>
          <Text>
            First select a model. The{" "}
            <Text as="span" fontWeight="bold">
              oneshard
            </Text>{" "}
            model is usually the best option for graph use cases. For document use cases, the{" "}
            <Text as="span" fontWeight="bold">
              sharded
            </Text>{" "}
            model is usually the best choice.
          </Text>
          <Text>After selecting a model, you select the size of each node of your deployment in terms of memory, CPU and disk size.</Text>
          <Text>If you need help choosing a suitable model and/or size for your deployment please submit a support request.</Text>
        </Flex>
      }
    />
  );
};

const getIsUpgradeAllowed = ({ currentModel, newModel }: { currentModel: string; newModel: string }) => {
  // allowed upgrade path: single server (i.e. developer) —> oneShard —> sharded —> flexible
  const upgradePath = {
    developer: ["developer", "oneshard", "sharded", "flexible"],
    oneshard: ["oneshard", "sharded", "flexible"],
    sharded: ["sharded", "flexible"],
    flexible: [],
  } as Record<string, string[]>;

  if (!currentModel || !newModel || !upgradePath[currentModel]) {
    return true;
  }
  return upgradePath[currentModel].includes(newModel);
};
