//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton } from "@arangodb/ui";
import { Flex, Grid } from "@chakra-ui/react";
import { Form, useFormikContext } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Routes } from "../../routes";
import { AdvancedSection } from "../newDeployment/AdvancedSection";
import { NameSection } from "../newDeployment/GeneralSection";
import { ProviderSelector } from "../newDeployment/ProviderSelector";
import { RegionSelector } from "../newDeployment/RegionsSelector";
import { SizingSection } from "../newDeployment/SizingSection";
import { SummaryBox } from "../newDeployment/SummaryBox";
import { TogglableSection } from "../newDeployment/TogglableSection";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { useFetchDeploymentForEdit } from "./useFetchDeploymentForEdit";
import { useFetchDeploymentMigrationStatus } from "./useFetchDeploymentMigrationStatus";

export const EditDeploymentForm = () => {
  const history = useHistory();
  const { project } = useProjectDetailsContext();
  const { data: deployment, isPrepaid, isExisting } = useFetchDeploymentForEdit();
  const deploymentId = deployment?.id;
  const { dirty } = useFormikContext();
  const { data: migrationStatus } = useFetchDeploymentMigrationStatus();

  return (
    <Grid as={Form} height="100%" templateColumns="minmax(300px, 1fr) minmax(200px, 0.5fr)" overflow="hidden">
      <Grid templateRows="min-content min-content min-content" height="100%" overflow="auto" paddingBottom="12">
        <TogglableSection title="General">
          <NameSection />
          <Grid gap="4" templateColumns="1fr 1fr">
            <ProviderSelector isDisabled={isPrepaid || isExisting} />
            <RegionSelector isDisabled={isPrepaid || isExisting} />
          </Grid>
        </TogglableSection>
        <SizingSection isDisabled={isPrepaid} />
        <AdvancedSection />
      </Grid>
      <Grid templateRows="repeat(auto-fill, 1fr)" gap="4" paddingRight="2" height="100%" overflow="auto">
        <SummaryBox />
        <Flex
          paddingTop="2"
          paddingRight="2"
          paddingLeft="4"
          paddingBottom="4"
          gap="2"
          direction="column"
          backgroundColor="white"
          position="sticky"
          bottom="0"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <FormButton marginTop={4} colorScheme="green" isDisabled={!dirty || !!migrationStatus?.status?.target_deployment_id}>
            Save changes
          </FormButton>
          <Button
            marginTop={4}
            onClick={() => {
              history.replace(Routes.dashboard_project_deployment_detailsWithId(project.id || "", deploymentId || ""));
            }}
          >
            Discard
          </Button>
        </Flex>
      </Grid>
    </Grid>
  );
};
