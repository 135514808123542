//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton } from "@arangodb/ui";
import { Box, Button, Flex, Grid, Heading, Spacer, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import apiClients from "../../api/apiclients";
import { RoleBinding } from "../../api/lib";
import { GroupsDropdown } from "./GroupsDropdown";
import { RolesDropdown } from "./RolesDropdown";
import { useFetchPolicies } from "./useFetchPolicies";
import { usePolicyContext } from "./usePolicyContext";
import { UsersDropdown } from "./UsersDropdown";

const useCreateRoleBindings = () => {
  const { resourceUrl } = usePolicyContext();
  const createRoleBindings = async ({ roles, users, groups }: { roles: string[]; users: string[]; groups: string[] }) => {
    const bindings = new Array<RoleBinding>();

    roles.forEach((roleId) => {
      users.forEach((userId) => {
        bindings.push({ role_id: roleId, member_id: `user:${userId}` });
      });
      groups.forEach((groupId) => {
        bindings.push({ role_id: roleId, member_id: `group:${groupId}` });
      });
    });

    return await apiClients.iamClient.AddRoleBindings({
      resource_url: resourceUrl,
      bindings: bindings,
    });
  };
  return useMutation({
    mutationFn: createRoleBindings,
  });
};
export const CreateRoleBindingSection = ({ onSuccess, onCancel }: { onSuccess: () => void; onCancel: () => void }) => {
  const { mutateAsync: createRoleBindings } = useCreateRoleBindings();
  const { refetch: refetchPolicyList } = useFetchPolicies();
  const toast = useToast();
  return (
    <Formik
      initialValues={{
        users: [],
        groups: [],
        roles: [],
      }}
      validationSchema={Yup.object().shape(
        {
          roles: Yup.array().of(Yup.string().required()).min(1, "At least one role is required"),
          // either users or groups should be selected
          users: Yup.array().when("groups", ([groups], schema) => {
            if (!groups || groups.length === 0) {
              return schema.of(Yup.string().required()).min(1, "At least one user or group is required");
            }
            return schema;
          }),
          groups: Yup.array().when("users", ([users], schema) => {
            if (!users || users.length === 0) {
              return schema.of(Yup.string().required()).min(1, "At least one group or user is required");
            }
            return schema;
          }),
        },
        [["groups", "users"]]
      )}
      onSubmit={async (values) => {
        await createRoleBindings(values);
        await refetchPolicyList();
        toast({
          title: "Role bindings created successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onSuccess();
      }}
    >
      <Form>
        <Box>
          <Heading size="md" paddingX="4">
            Member info
          </Heading>
          <Grid gap="4" templateColumns="1fr 1fr" padding="4">
            <UsersDropdown />
            <GroupsDropdown />
          </Grid>
          <Heading size="md" paddingX="4">
            Role info
          </Heading>
          <Grid padding="4" templateColumns="1fr 1fr">
            <RolesDropdown />
            <Spacer />
          </Grid>
          <Flex gap="2" padding="4">
            <Button colorScheme="gray" onClick={onCancel}>
              Cancel
            </Button>
            <FormButton type="submit" colorScheme="green">
              Create
            </FormButton>
          </Flex>
        </Box>
      </Form>
    </Formik>
  );
};
