//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner } from "@arangodb/ui";
import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { useDisableTopMenu } from "../../components/useDisableTopMenu";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { NewDeploymentFormValues } from "../newDeployment/newDeploymentForm.types";
import { useFetchCACertificates } from "../newDeployment/useFetchCACertificates";
import { useFetchDeploymentModels } from "../newDeployment/useFetchDeploymentModels";
import { useFetchNodeSizes } from "../newDeployment/useFetchNodeSizes";
import { useFetchProviders } from "../newDeployment/useFetchProviders";
import { useFetchRegions } from "../newDeployment/useFetchRegions";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { DownsizingAlertDialog } from "./DownsizingAlertDialog";
import { EditDeploymentFormContainer } from "./EditDeploymentFormContainer";
import { UpgradeConfirmationModal } from "./UpgradeConfirmationModal";
import { useFetchDeploymentForEdit } from "./useFetchDeploymentForEdit";
import { useFetchDeploymentMigrationStatus } from "./useFetchDeploymentMigrationStatus";
import { useFetchRegion } from "./useFetchRegion";

export const EditDeploymentPage = () => {
  useDisableTopMenu();
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();
  const { refetchDeployments } = useProjectDetailsContext();
  const toast = useToast();
  const { isOpen: isDownsiszeAlertOpen, onOpen: onOpenDownsiszeAlert, onClose: onCloseDownsiszeAlert } = useDisclosure();

  const { data: deployment, refetch: refetchCurrentDeployment, isLoading } = useFetchDeploymentForEdit();
  const { isLoading: isLoadingModels } = useFetchDeploymentModels();
  const { isLoading: isLoadingProviders } = useFetchProviders();
  const { isLoading: isLoadingCertificates } = useFetchCACertificates();
  // we need to fetch the region to get the providerId as the deployment doesn't store it
  const { data: region } = useFetchRegion(deployment?.region_id);
  const { isLoading: isLoadingRegions } = useFetchRegions({ providerId: region?.provider_id, modelId: deployment?.model?.model || "" });
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: deployment?.region_id || "",
    modelId: deployment?.model?.model || "",
  });
  const history = useHistory();
  const { checkSpecificDeploymentPermission } = useCheckPermissionByUrl();
  const canUpgradePerpetuallyFreeDeployment = deployment?.id ? checkSpecificDeploymentPermission("replication.deploymentmigration.create", deployment) : false;
  const { isLoading: isLoadingMigrationStatus, data: migrationStatus } = useFetchDeploymentMigrationStatus();
  const [upgradeConfirmationStatus, setUpgradeConfirmationStatus] = React.useState<"none" | "ask" | "confirmed">("none");
  if (isLoading || isLoadingRegions || isLoadingModels || isLoadingProviders || isLoadingCertificates || isLoadingMigrationStatus) {
    return (
      <Box height="100vh">
        <PageSpinner isLoading height="calc(100% - 40px)" />
      </Box>
    );
  }
  const customDiskSize = {
    [deployment?.model?.node_size_id || ""]: deployment?.model?.node_disk_size || 0,
  };
  const initialValues = {
    name: deployment?.name || "",
    description: deployment?.description || "",
    modelId: deployment?.model?.model || "",
    providerId: region?.provider_id || "",
    regionId: deployment?.region_id || "",
    supportPlanId: deployment?.support_plan_id || "",
    dbVersion: deployment?.version || "",
    numberOfNodes: deployment?.model?.node_count || 0,
    diskPerformanceId: deployment?.disk_performance_id || "",
    certificate: deployment?.certificates?.ca_certificate_id || "",
    customImage: deployment?.custom_image || "",
    ipAllowlistId: deployment?.ipallowlist_id || "",
    iamProviderId: deployment?.iamprovider_id || "",
    nodeSizeId: deployment?.model?.node_size_id || "",
    deploymentProfileId: deployment?.deployment_profile_id || "",
    customDiskSize,
  } satisfies NewDeploymentFormValues;

  const updateDeployment = async (values: NewDeploymentFormValues) => {
    if (!deployment || !!migrationStatus?.status?.target_deployment_id) {
      return;
    }

    // if it's a free model and the user hasn't confirmed the upgrade, ask for confirmation
    if (
      canUpgradePerpetuallyFreeDeployment &&
      isPerpetualFreeTrialAvailable &&
      deployment.model?.model === "free" &&
      values.modelId !== "free" &&
      upgradeConfirmationStatus === "none"
    ) {
      setUpgradeConfirmationStatus("ask");
      return;
    }

    const certs = {
      ca_certificate_id: values.certificate,
    };

    let nodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size || 0;
    if (values.customDiskSize && values.customDiskSize[values.nodeSizeId]) {
      nodeDiskSize = values.customDiskSize[values.nodeSizeId];
    }

    const deploymentPatch = {
      id: deployment.id,
      project_id: deployment.project_id,
      name: values.name,
      description: values.description,
      //region_id: selectedRegion.id, //currently updating region isn't supported
      version: values.dbVersion,
      custom_image: values.customImage,
      certificates: certs,
      model: {
        model: values.modelId,
        node_size_id: values.nodeSizeId,
        node_disk_size: nodeDiskSize,
        node_count: values.numberOfNodes,
      },
      // servers: values.servers, // not supporting flexible deployments
      // disk_auto_size_settings: values.disk_auto_size_settings, // deprecated
      ipallowlist_id: values.ipAllowlistId,
      is_platform_authentication_enabled: deployment ? deployment.is_platform_authentication_enabled : undefined,
      disable_foxx_authentication: deployment ? deployment.disable_foxx_authentication : undefined,
      disk_performance_id: values.diskPerformanceId,
    };

    try {
      const updatedDeployment = await apiClients.dataClient.UpdateDeployment(deploymentPatch);
      toast({
        title: "Deployment updated",
        description: "The deployment has been updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetchCurrentDeployment();
      refetchDeployments();
      history.replace(Routes.dashboard_project_deployment_detailsWithId(deployment.project_id || "", deployment.id || ""));
      return updatedDeployment;
    } catch (error) {
      toast({
        title: "Failed to edit deployment",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmit = async (values: NewDeploymentFormValues) => {
    let currentNodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size || 0;
    if (values.customDiskSize && values.customDiskSize[values.nodeSizeId]) {
      currentNodeDiskSize = values.customDiskSize[values.nodeSizeId];
    }

    const isDeploymentBeingDownsized = currentNodeDiskSize < (deployment?.model?.node_disk_size || 0);

    // Alert users the consequences of downgrading the disk size of a deployment
    if (isDeploymentBeingDownsized) {
      return onOpenDownsiszeAlert();
    }

    await updateDeployment(values);
  };

  return (
    <Formik<NewDeploymentFormValues> initialValues={initialValues} onSubmit={onSubmit}>
      <>
        <DownsizingAlertDialog onClose={onCloseDownsiszeAlert} isOpen={isDownsiszeAlertOpen} updateDeployment={updateDeployment} />
        <UpgradeConfirmationModal status={upgradeConfirmationStatus} onClose={() => setUpgradeConfirmationStatus("none")} />
        <EditDeploymentFormContainer />
      </>
    </Formik>
  );
};
