//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Route, Router } from "react-router-dom";
import App from "./App";
import AccessDenied from "./accessdenied/AccessDenied";
import EmailNeedsVerification from "./accessdenied/EmailNeedsVerification";
import api from "./api/api";
import Auth from "./auth/Auth";
import Authorize from "./authorize/Authorize";
import Callback from "./callback/Callback";
import DashboardBootstrap from "./dashboard/DashboardBootstrap";
import history from "./history";
import DataProcessingAddendum from "./home/DataProcessingAddendum";
import EmailVerified from "./home/EmailVerified";
import Faq from "./home/Faq";
import Home from "./home/Home";
import Logos from "./home/Logos";
import PrivacyPolicy from "./home/PrivacyPolicy";
import Regions from "./home/Regions";
import SupportPlans from "./home/SupportPlans";
import TermsAndConditions from "./home/TermsAndConditions";
import Logout from "./logout/Logout";
import OnboardingBootstrap from "./onboarding/OnboardingBootstrap";
import { Routes } from "./routes";
import Tracking from "./tracking/tracking";
import { PermissionCache } from "./util/PermissionCache";
import { PersistentState } from "./util/PersistentState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@arangodb/ui";

const tracking = new Tracking();
const auth = new Auth(tracking);
const permissionCache = new PermissionCache();

const handleAuthentication = (location: any) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const logout = () => {
  auth.logout();
  permissionCache.clear();
  PersistentState.removeRedirection();
  api.token = "";
};

function trackPageViews(location: any) {
  tracking.trackPageView(location.pathname);
  return null;
}

function setReferrerOverride() {
  tracking.setReferrerOverride();
  return null;
}
const queryClient = new QueryClient();

export const MakeMainRoutes = () => {
  const args = {
    auth: auth,
    tracking: tracking,
  };
  tracking.configureGTag();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider>
        <Router history={history}>
          <div>
            <Route path={Routes.callback} render={(props) => setReferrerOverride()} />
            <Route path={Routes.email_verified} render={(props) => setReferrerOverride()} />
            <Route path={Routes.email_verified_entry} render={(props) => setReferrerOverride()} />
            <Route path="*" render={(props) => trackPageViews(props.location)} />
            <Route path={Routes.root} render={(props) => <App {...props} {...args} />} />
            <Route
              path={[Routes.home, Routes.requestHelp, Routes.signup, Routes.directSignup, Routes.login]}
              render={(props) => <Home {...props} {...args} />}
            />
            <Route path={Routes.privacy_policy} render={(props) => <PrivacyPolicy {...props} {...args} />} />
            <Route path={Routes.terms_and_conditions} render={(props) => <TermsAndConditions {...props} {...args} />} />
            <Route path={Routes.data_processing_addendum} render={(props) => <DataProcessingAddendum {...props} {...args} />} />
            <Route path={Routes.regions} render={(props) => <Regions {...props} {...args} />} />
            <Route path={Routes.support} render={(props) => <SupportPlans {...props} {...args} />} />
            <Route path={Routes.faq} render={(props) => <Faq {...props} {...args} />} />
            <Route path={Routes.accessdenied} render={(props) => <AccessDenied {...props} {...args} />} />
            <Route path={Routes.email_needs_verification} render={(props) => <EmailNeedsVerification {...props} {...args} />} />
            <Route path={Routes.email_verified} render={(props) => <EmailVerified {...props} {...args} />} />
            <Route path={Routes.email_verified_entry} render={(props) => <EmailVerified {...props} entry {...args} />} />
            <Route
              path={Routes.onboarding}
              render={(props) => <OnboardingBootstrap {...props} {...args} permissionCache={permissionCache} onClickLogout={logout} />}
            />
            <Route path={Routes.authorize} render={(props) => <Authorize {...args} {...props} />} />
            <Route path={Routes.logout} render={(props) => <Logout {...args} {...props} />} />
            <Route
              path={Routes.dashboard}
              render={(props) => (
                <DashboardBootstrap
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  auth={args.auth}
                  tracking={args.tracking}
                  permissionCache={permissionCache}
                  onClickLogout={logout}
                />
              )}
            />
            <Route
              path={Routes.callback}
              render={(props) => {
                handleAuthentication(props.location);
                return <Callback />;
              }}
            />
            <Route path={Routes.logos} render={(props) => <Logos {...props} {...args} />} />
          </div>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
