//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InputControl } from "@arangodb/ui";
import { Grid } from "@chakra-ui/react";
import React from "react";
import { ProviderSelector } from "./ProviderSelector";
import { RegionSelector } from "./RegionsSelector";
import { TogglableSection } from "./TogglableSection";

export const GeneralSection = () => {
  return (
    <TogglableSection title="General">
      <NameSection />
      <Grid gap="4" templateColumns="1fr 1fr">
        <ProviderSelector />
        <RegionSelector />
      </Grid>
    </TogglableSection>
  );
};

export const NameSection = () => {
  return (
    <Grid gap="4" templateColumns="1fr 1fr">
      <InputControl isRequired label="Name" name="name" tooltip="Give your deployment a recognizable name" />
      <InputControl
        label="Description"
        name="description"
        inputProps={{
          placeholder: "Short description",
        }}
      />
    </Grid>
  );
};
