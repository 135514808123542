//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { SingleSelectControl } from "@arangodb/ui";
import React from "react";
import { useFetchDeploymentProfiles } from "./useFetchDeploymentProfiles";

export const DeploymentProfileSelector = () => {
  const { data: deploymentProfiles } = useFetchDeploymentProfiles();

  return (
    <SingleSelectControl
      label="Deployment Profile"
      name="deploymentProfileId"
      selectProps={{
        options: deploymentProfiles?.items?.map((deploymentProfile) => ({
          label: deploymentProfile.name || "",
          value: deploymentProfile.id || "",
        })),
        isClearable: true,
      }}
      tooltip="Deployment profiles are designed for deployment creation customizations. The deployment will be created with predefined configuration."
    />
  );
};
