//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { MultiSelectControl } from "@arangodb/ui";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

const useFetchUsers = () => {
  const { selectedOrganization } = useDashboardContext();

  return useQuery(["users"], async () => {
    const organizationMembers = await apiClients.resourceManagerClient.ListOrganizationMembers({
      context_id: selectedOrganization.id,
    });
    return organizationMembers;
  });
};
export const UsersDropdown = () => {
  const { data: users, isLoading } = useFetchUsers();
  const options = users?.items?.map((user) => ({
    label: user.user?.name || "",
    value: user.user?.id || "",
  }));
  return (
    <MultiSelectControl
      label="Users"
      name="users"
      selectProps={{
        options,
        isLoading,
        placeholder: "Select users",
        closeMenuOnSelect: false,
      }}
    />
  );
};
